<template>
    <fragment>
        <img id="bg-image" src="/assets/banner/prueba.jpeg" />
        <section class="hero is-fullheight">
            <div class="hero-body is-justify-content-center">
                <div class="card login">
                    <div class="card-content" style="padding: 0">
                        <auth-slider :showedForm="showedForm" />
                    </div>
                </div>
            </div>
        </section>
    </fragment>
</template>

<script>
import AuthSlider from '@/components/Login/AuthSlider.vue';

export default {
    name: 'Login',
    components: {
        AuthSlider,
    },
    data() {
        return {
            showedForm: 'login',
        };
    },
    mounted() {
        this.showedForm = this.$route.query.sf;
    },
};
</script>

<style scoped>
:root {
    --shadowDark: #d9dde6;
    --background: #e4e9f2;
    --shadowLight: #eff5fe;
}

.login {
    width: 50%;
    height: 85vh !important;
    max-height: 800px !important;
    border-radius: 5px;
}

#bg-image {
    position: fixed;
    top: 0;
    right: 0;
    min-height: 100%;
    min-width: 100%;
    animation-name: kenburns-top;
    animation-duration: 15s;
    animation-iteration-count: infinite;
}

#bg-image:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
}

@keyframes kenburns-top {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.15);
    }
}

@media screen and (max-width: 1200px) {
    .login {
        width: 55%;
    }
}

@media screen and (max-width: 992px) {
    .login {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .login {
        width: 85%;
    }
}

@media screen and (max-width: 600px) {
    .login {
        width: 100%;
    }
}
</style>
