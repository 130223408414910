<template>
    <div class="login-form">
        <div class="is-flex is-justify-content-center my-5">
            <logo class="logo" />
        </div>
        <b-message type="is-danger" v-if="message">
            <b>Error</b>: {{ message }}
        </b-message>
        <br v-else />
        <div v-if="!unlock_user_form">
            <form @submit.prevent="handleLogin">
                <b-field label="Usuario o Email" label-position="on-border">
                    <b-input
                        v-model="user.username"
                        name="usuario"
                        autocomplete="off"
                        required
                    ></b-input>
                </b-field>
                <b-field label="Contraseña" label-position="on-border">
                    <b-input
                        v-model="user.password"
                        name="contraseña"
                        type="password"
                        password-reveal
                        required
                    ></b-input>
                </b-field>
                <br />
                <button
                    class="button is-block is-fullwidth is-primary"
                    :class="{ 'is-loading': loading }"
                    :disabled="loading"
                    type="submit"
                >
                    Iniciar Sesión
                </button>
            </form>
            <br />
            <nav class="level">
                <div class="level-left">
                    <div class="level-item has-text-left m-1">
                        <div>
                            <a href="/forgot-password"
                                ><small>¿Olvidaste tu contraseña?</small></a
                            >
                        </div>
                    </div>
                </div>
                <!-- <div class="level-item has-text-centered m-1">
                    <div>
                        <a id="goRight" @click.prevent="goRight"><small>Crea una cuenta</small></a>
                    </div>
                </div> -->
            </nav>
        </div>
        <div v-else>
            <form @submit.prevent="changePassword">
                <div class="content has-text-centered">
                    <h5 class="has-text-primary">Primer ingreso</h5>
                </div>
                <b-field label="Email (opcional)" label-position="on-border">
                    <b-input
                        name="email"
                        v-model="forms.change_password.email"
                    ></b-input>
                </b-field>
                <b-field label="Contraseña" label-position="on-border">
                    <b-input
                        v-model="forms.change_password.new_password"
                        name="contraseña"
                        type="password"
                        password-reveal
                        required
                    ></b-input>
                </b-field>
                <b-field
                    label="Confirmar Contraseña"
                    label-position="on-border"
                >
                    <b-input
                        v-model="
                            forms.change_password.new_password_confirmation
                        "
                        name="confirmar_contrseña"
                        type="password"
                        password-reveal
                        required
                    ></b-input>
                </b-field>
                <br />
                <b-button
                    :loading="loading"
                    type="is-primary"
                    native-type="submit"
                    expanded
                >
                    Enviar
                </b-button>
            </form>
        </div>

        <!-- Modals -->
        <modal-seleccionar-perfil />
    </div>
</template>

<script>
import User from '@/models/User';
import Message from '@/utils/Message';

// Components
import Logo from '@/components/shared/Logo';
import Perfiles from '@/components/Login/Perfiles';
import ModalSeleccionarPerfil from '@/components/shared/Modals/ModalSeleccionarPerfil.vue';

// Config
import { RECAPTCHA_PUBLIC_KEY } from '@/config';

export default {
    components: {
        Perfiles,
        Logo,
        ModalSeleccionarPerfil,
    },
    data() {
        return {
            user: new User('', ''),
            loading: false,
            message: '',
            unlock_user_form: false,
            tmpToken: null,
            forms: {
                change_password: {
                    email: '',
                    new_password: '',
                    new_password_confirmation: '',
                },
            },
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            //this.$router.push({ path: '/' });
            location.href = '/';
        }
    },
    mounted() {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute(
            'src',
            `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_PUBLIC_KEY}`
        );
        document.head.appendChild(recaptchaScript);
    },
    methods: {
        async handleLogin() {
            this.loading = true;
            try {
                const token = await window.grecaptcha.execute(
                    RECAPTCHA_PUBLIC_KEY,
                    { action: 'submit' }
                );

                if (this.user.username && this.user.password) {
                    this.user.recaptcha_token = token;

                    const result = await this.$store.dispatch(
                        'auth/login',
                        this.user
                    );
                    this.tmpToken = result?.token ?? null;

                    // Identificando si tiene contraseña temporal
                    if (!result.temporal) {
                        await this.$store.dispatch('me/setPerfiles', []);

                        if (!result.access) {
                            if (result.perfiles.length > 1) {
                                await this.$store.dispatch(
                                    'me/setPerfiles',
                                    result.perfiles
                                );
                                await this.$store.dispatch(
                                    'ui/setisModalSeleccionarPerfil',
                                    true
                                );
                                return;
                            } else if (result.perfiles.length === 0) {
                                await this.$router.push('/nueva-cuenta');
                            }
                        }
                        //await this.$router.push({ path: '/' });
                        location.href = '/';
                    } else {
                        this.unlock_user_form = true;
                    }
                }
            } catch (err) {
                Message.showErrorMessage(this, err.message, err?.errors);
            } finally {
                this.loading = false;
            }
        },
        async changePassword() {
            try {
                this.loading = true;

                const res = await this.$store.dispatch('auth/firstLogin', {
                    ...this.forms.change_password,
                    temporalToken: this.tmpToken,
                });

                Message.showSuccessMessage(this, res.message);
                setTimeout(() => {
                    window.location.replace('/login');
                }, 2000);
            } catch (err) {
                Message.showErrorMessage(this, err.message, err?.errors);
            } finally {
                this.loading = false;
            }
        },
        goRight() {
            this.$emit('go-side', 'right');
        },
    },
};
</script>

<style scoped>
.login-form {
    padding: 1.5rem;
}

.logo {
    width: 70%;
    margin: 50px 0 20px;
}
</style>
