<template>
    <img :src="require('@/assets/images/branding/logo-grupo-verfrut-gh.png')" />
</template>

<script>
export default {
    name: 'Logo',
};
</script>

<style></style>
