<template>
    <div class="register-form">
        <div class="is-flex is-justify-content-center my-5">
            <img
                :src="require('@/assets/images/branding/logotipo.png')"
                style="width: 60%"
            />
        </div>
        <b-message type="is-danger" v-if="message">
            <b>Error</b>: {{ message }}
        </b-message>
        <br v-else />
        <form @submit.prevent="handleRegister">
            <autocomplete-paises
                v-model="user.persona.pais_id"
            />
            <b-field label="DNI" label-position="on-border" >
                <b-input
                    v-model="user.persona.dni"
                    name="dni"
                    autocomplete="off"
                    required
                />
            </b-field>
            <!-- <b-field
                label="Usuario"
                label-position="on-border"
                :type="usernameAvailability.availability !== null ? (usernameAvailability.availability ? 'is-success' : 'is-danger') : null"
                :message="!usernameAvailability.availability ? usernameAvailability.message : ''"
            >
                <b-input
                    v-model="user.username"
                    :loading="loading.username"
                    name="usuario"
                    @input="verifyUsernameAvailability()"
                    lazy
                    required
                ></b-input>
            </b-field> -->
            <b-field label="Usuario" label-position="on-border">
                <b-input
                    v-model="user.username"
                    :placeholder="user.persona.dni !== '' && `Por defecto: ${user.persona.dni}`"
                    name="username"
                    autocomplete="off"
                ></b-input>
            </b-field>
            <b-field label="Email" label-position="on-border">
                <b-input
                    v-model="user.email"
                    type="email"
                    name="email"
                    autocomplete="off"
                    required
                ></b-input>
            </b-field>
            <b-field label="Contraseña" label-position="on-border">
                <b-input
                    v-model="user.password"
                    name="contraseña"
                    type="password"
                    password-reveal
                    required
                ></b-input>
            </b-field>
            <b-field label="Confirmar Contraseña" label-position="on-border">
                <b-input
                    v-model="user.password_confirmation"
                    name="contraseña"
                    type="password"
                    password-reveal
                    required
                ></b-input>
            </b-field>
            <b-field>
                <b-checkbox v-model="confirm_register" required>
                    <small>He leído y acepto los <a href="#" target="_blank"><small>Términos y Condiciones</small></a></small>
                </b-checkbox>
            </b-field>
            <button
                class="button is-block is-fullwidth is-primary"
                :class="{ 'is-loading': loading.submit }"
                :disabled="!enableRegister"
                type="submit"
            >
                Registrar
            </button>
        </form>
        <br />
        <nav class="level">
            <div class="level-left">
                <div class="level-item has-text-left m-1">
                    <div>
                        <a id="goLeft" @click.prevent="goLeft"
                            ><small>Ya tengo una cuenta</small></a
                        >
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import AutocompletePaises from '../shared/FormControls/AutocompletePaises.vue';
import Message from '@/utils/Message';

export default {
    components: {
        AutocompletePaises,
    },
    data() {
        return {
            loading: {
                submit: false,
                paises: false,
                username: false,
            },
            message: '',
            user: {
                username: '',
                email: '',
                password: '',
                password_confirmation: '',
                persona: {
                    dni: '',
                    pais_id: '',
                },
            },
            excludeParams: ['username'],
            confirm_register: false,
        };
    },
    computed: {
        enableRegister() {
            let flag = true;
            for (const key in this.user) {
                if (this.excludeParams.findIndex(e => e === key) === -1) {
                    if (this.user[key] === '') {
                        flag = false;
                    }
                }
            }

            for (const key in this.user.persona) {
                if (this.user.persona[key] === '') {
                    flag = false;
                }
            }

            if (!this.confirm_register) {
                return false;
            }

            return flag;
        },
        usernameAvailability() {
            return this.$store.state.usuarios.usernameAvailabilityResponse;
        },
    },
    methods: {
        async handleRegister() {
            this.loading.submit = true;
            try {
                const res = await this.$store.dispatch('auth/signup', this.user);
                Message.showSuccessMessage(this, res.message);

                setTimeout(() => {
                    this.$router.go();
                }, 1500);
            } catch (err) {
                Message.showErrorMessage(this, err?.message, err?.errors)
            } finally {
                this.loading.submit = false;
            }
        },
        async verifyUsernameAvailability() {
            this.loading.username = true;
            await this.$store.dispatch(
                'usuarios/getUsernameAvailability',
                this.user.username
            );
            this.loading.username = false;
        },
        goLeft() {
            this.$emit('go-side', 'left');
        },
    },
};
</script>

<style>
.register-form {
    padding: 1.5rem;
}
</style>
