<template>
    <div class="auth-slider">
        <!-- Imagenes Modal -->
        <div id="back" class="back">
            <div class="back-right"></div>
            <div class="back-left"></div>
        </div>
        <div id="slideBox" class="slide-box" style="margin-left: 0px">
            <div id="topLayer" class="top-layer" style="margin-right: 100px">
                <div class="left">
                    <!-- Register Form -->
                    <!-- <register-form @go-side="goSide" /> -->
                </div>
                <div class="right">
                    <!-- Login Form -->
                    <login-form @go-side="goSide" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

import LoginForm from '@/components/Login/LoginForm.vue';
import RegisterForm from '@/components/Login/RegisterForm.vue';

export default {
    name: 'AuthSlider',
    components: {
        LoginForm,
        RegisterForm,
    },
    props: {
        showedForm: String,
    },
    watch: {
        showedForm() {
            if (this.showedForm === 'register') {
                setTimeout(() => {
                    this.goSide('right');
                }, 500);
            }
        },
    },
    methods: {
        goSide(side) {
            if (side === 'right') {
                $('#topLayer').animate({
                    marginLeft: '100%',
                });

                $('#slideBox').animate({
                    marginRight: '0',
                    marginLeft: '50%',
                });
            } else {
                $('#topLayer').animate({
                    marginLeft: '0',
                });

                $('#slideBox').animate({
                    marginRight: '50%',
                    marginLeft: '0',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.back-right {
    background: url('../../assets/images/auth-right-slide.jpg');
}

.back-left {
    left: 0;
    background: url('../../assets/images/auth-right-slide.jpg');
}

.slide-box,
.back-left,
.back-right {
    width: 50%;
    height: 100%;
    position: absolute;
}

.back-right,
.right {
    right: 0;
}

.back-left,
.back-right {
    background-size: cover;
    background-position: 50% 50%;
}

.back-right::before,
.back-left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
}

.slide-box {
    max-height: 100%;
    margin-left: 50%;
    overflow: hidden;
}

.top-layer {
    width: 200%;
    height: 100%;
    position: relative;
    left: -100%;
}

.left {
    left: 0;
}

.left,
.right {
    position: absolute;
    width: 50%;
    height: 100%;
    background: #fff;
}

@media screen and (max-width: 600px) {
    #slideBox {
        width: 100%;
        margin-left: 0 !important;
    }
}
</style>
